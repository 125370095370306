import React from 'react';
import { Routes, Route, BrowserRouter } from 'react-router-dom';
//import Home from "./Pages/Home";
//import Admin from "../src/Pages/admin";
import Auctions from "../src/Pages/Auctions";
 
function App() {

	return (
		<div>
			<BrowserRouter>
				<Routes>

					<Route path='/' element={<Auctions />} />
				/
 
				</Routes>
			</BrowserRouter>

		</div>

	)
}

export default App;
